html{
}
body {
  background-image: url('fade.png'), url('darken.png'), url("background-modified.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-color: #000;
  background-size: cover;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  margin-top: 8vh;
  width: 20vw;
}

@media only screen and (min-width: 600px) {
  .logo {
    margin-top: 8vh;
    width: 7vw !important;
  }
}

.collect {
  margin-top: 33px;
}

.collect:hover {
  cursor: pointer;
}

.view {
  margin-top: 33px;
}

.view:hover {
  cursor: pointer;
}

.collect-history {
  width: 200px;
}

.vertical-line {
  position: absolute; /* position the element relative to the nearest positioned ancestor */
  left: 50%; /* move the element 50% to the left */
  transform: translateX(-50%); /* move the element back to the center */
  height: 10vh; /* set the height of the line to 100px */
  width: 1px; /* set the width of the line to 1px */
  background-color: white; /* set the color of the line to white */
  margin-top: -1.3vh;
}

.video-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  width: 84vw;
  /* border: 7px solid blue; */
  margin-top: 5vh;
  border-radius: 5px;
}

.container {
  position: relative;
}

.button-text {
  font-size: 26px;
  font-family: 'Diamante';
  color: white;
  margin-top: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .video-container {
    margin-top: 30px !important;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    width: 25vw;
    border-radius: 5px;
  }
}

.claiming-loading {
  font-family: 'Diamante';
  color: white;
}

.support {
  font-family: 'Diamante';
  color: white;
  font-size: 14px;
}

video {
  border-radius: 5px;
  height: 27vh;
  width: 84vw;
  object-fit: fill;
}